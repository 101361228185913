.chevron-breadcrumbs {
    list-style: none;
    overflow-y: hidden;
    overflow-x: auto;

    $even-bg: hsla(34,85%,45%,1);
    $odd-bg: hsla(34,85%,65%,1);

    $hover-bg: #C1E1A6;

    $active-color: white;
    $active-bg: #118C4E;

    ul {
        padding-left: 0;
        width: 10000px;
    }

    li {
        float: left;
    }
    li {
        span {
            color: white;
            text-decoration: none;
            background: brown; /* fallback color */
            background: hsla(34, 85%, 35%, 1);
            position: relative;
            display: block;
            float: left;
        }
        span {
            padding: 5px 0 5px 55px;

            &:hover {
                cursor: default;
            }
        }
        a {
            color: white;
            padding: 5px 10px 5px 10px;

            &:hover {
                cursor: pointer;
            }
        }
    }
    li span:after {
        content: " ";
        display: block;
        width: 0;
        height: 0;
        border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
        border-bottom: 50px solid transparent;
        border-left: 30px solid hsla(34, 85%, 35%, 1);
        position: absolute;
        top: 50%;
        margin-top: -50px;
        left: 100%;
        z-index: 2;
    }
    li span:before {
        content: " ";
        display: block;
        width: 0;
        height: 0;
        border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
        border-bottom: 50px solid transparent;
        border-left: 30px solid white;
        position: absolute;
        top: 50%;
        margin-top: -50px;
        margin-left: 1px;
        left: 100%;
        z-index: 1;
    }


    li:first-child {
        span {
            padding-left: 25px;
        }
    }

    li:nth-child(odd) {
        span {
            background: $odd-bg;
        }
    }

    li:nth-child(odd) span:after {
        border-left-color: $odd-bg;
    }

    li:nth-child(even) {
        span {
            background: $even-bg;
        }
    }

    li:nth-child(even) span:after {
        border-left-color: $even-bg;
    }

    li.active {
        span {
            background: $active-bg;
            color: $active-color;
            //pointer-events: none;
            //cursor: default;
        }
    }

    li.active span:after {
        border-left-color: $active-bg;
    }

    li:hover {
        span {
            background: $hover-bg;
        }
    }

    li:hover span:after {
        border-left-color: $hover-bg;
    }
}
