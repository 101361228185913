// Customize the look and feel of the grid with Sass variables
// Up-to-date list of variables is available in the source code: https://github.com/ag-grid/ag-grid/blob/latest/src/styles/ag-theme-fresh.scss
$icons-path: "../scssVendor/ag-grid/ag-theme-base/icons/";

$header-background-color: #eee;
$header-text-color: black;

$default-base-color: #f6f6f6;

$row-even-color: #fff;
$row-odd-color: darken($default-base-color, 5);
$dark-row-color: darken($default-base-color, 15);
$empty-even-row-color: darken($default-base-color, 25);
$empty-odd-row-color: darken($default-base-color, 35);

$row-highlighter-color: powderblue;
$cell-font-size: 10px;
$cell-border-color: #ddd;
$button-border-color: #ccc;
$paging-button-color: white;
$paging-button-color-disabled: #eee;
$table-border-color: $cell-border-color;
$font-family-sans-serif: "Helvetica Neue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

$tooltip-background-color: #222;
$tooltip-foreground-color: #fff;
$tooltip-border-radius: 2px;
$tooltip-padding: 5px;
$tooltip-border-width: 1px;
$tooltip-border-style: solid;
$tooltip-border-color: #000;


@import '../scssVendor/ag-grid/ag-grid';
@import '../scssVendor/ag-grid/ag-theme-fresh/sass/ag-theme-fresh';

/*
 * YD-specific customizations
 */

div.ag-root {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.ag-yd-style {
    line-height: 1.4;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    color: #000;
    height: 580px;

    /* this is for the rowGroupPanel, that appears along the top of the grid */
    /* this is for the column drops that appear in the toolPanel */

    img {
        vertical-align: middle;
        border: 0;
    }

    .ag-root {
        //border: solid 1px $table-border-color;
        font-size: $cell-font-size;
    }

    .ag-cell-not-inline-editing {
        padding: 4px;
    }

    .ag-cell-range-selected-1:not(.ag-cell-focus) {
        background-color: rgba(120, 120, 120, 0.4);
    }

    .ag-cell-range-selected-2:not(.ag-cell-focus) {
        background-color: rgba(80, 80, 80, 0.4);
    }

    .ag-cell-range-selected-3:not(.ag-cell-focus) {
        background-color: rgba(40, 40, 40, 0.4);
    }

    .ag-cell-range-selected-4:not(.ag-cell-focus) {
        background-color: rgba(0, 0, 0, 0.4);
    }

    .ag-cell-no-focus {
        border-top: 1px solid $cell-border-color;
    }

    .ag-ltr .ag-cell-no-focus {
        //border-right: none;
        border-right: 1px solid $cell-border-color;
    }

    .ag-cell {
        padding: 2px;

        button.btn-xs, a.btn-xs {
            font-size: 9px;
            line-height: 1.2;
            text-decoration: none;
        }

        a {
            text-decoration: underline;

            &.btn-fa {
                font-size: 0.9em;
                text-decoration: none;
            }
        }

        &.overflow-visible {
            overflow: visible;
        }
    }

    .ag-cell:not(:last-child) {
        //border-right: 1px solid $cell-border-color;
    }

    .ag-cell:first-child {
        border-left: 1px solid $cell-border-color;
    }

    .ag-rtl .ag-cell-no-focus {
        border-left: none;
    }

    .ag-rtl .ag-cell-first-right-pinned {
        border-left: none;
    }

    .ag-ltr .ag-cell-first-right-pinned {
        //border-left: none;
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: $cell-border-color;
    }

    .ag-rtl .ag-cell-last-left-pinned {
        border-right: none;
    }

    .ag-ltr .ag-cell-last-left-pinned {
        //border-right: none;
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: $cell-border-color;
    }

    .ag-cell-highlight {
        border: 1px solid #006400;
    }

    .ag-cell-highlight-animation {
        -webkit-transition: border 1s;
        -moz-transition: border 1s;
        -o-transition: border 1s;
        -ms-transition: border 1s;
        transition: border 1s;
    }

    .ag-value-change-delta {
        padding-right: 2px;
    }

    .ag-value-change-delta-up {
        color: #006400;
    }

    .ag-value-change-delta-down {
        color: #8b0000;
    }

    .ag-value-change-value {
        background-color: transparent;
        border-radius: 1px;
        padding-left: 1px;
        padding-right: 1px;
        -webkit-transition: background-color 1s;
        -moz-transition: background-color 1s;
        -o-transition: background-color 1s;
        -ms-transition: background-color 1s;
        transition: background-color 1s;
    }

    .ag-value-change-value-highlight {
        background-color: #cec;
        -webkit-transition: background-color 0.1s;
        -moz-transition: background-color 0.1s;
        -o-transition: background-color 0.1s;
        -ms-transition: background-color 0.1s;
        transition: background-color 0.1s;
    }

    .ag-rich-select {
        font-size: $cell-font-size;
        border: none;
        background-color: #fff;
    }

    .ag-rich-select-value {
        padding: 2px;
    }

    .ag-rich-select-list {
        border-top: 1px solid $cell-border-color;
    }

    .ag-rich-select-row {
        padding: 2px;
    }

    .ag-rich-select-row-selected {
        background-color: $row-highlighter-color;
    }

    .ag-large-text {
        border: none;
    }

    .ag-header-select-all {
        padding: 5px 0px 0px 7px;
        line-height: 0;
    }

    .ag-header {
        color: $header-text-color;
        background: $header-background-color;
        border-bottom: none;
        font-weight: 600;
    }

    .ag-header-icon {
        color: #000;
        stroke: none;
        fill: #000;
        margin-top: 1px;
    }

    .ag-no-scrolls .ag-header-container {
        background: none;
        border-bottom: none;
    }

    .ag-header-cell {
        border-right: 1px solid $cell-border-color;

        /*
        .ag-floating-filter-body, .ag-floating-filter-full-body {
            .input-group .input-group-addon {
                padding: 2px 2px;
                border-bottom: 0;
                border-left: 0;

                .fa {
                    font-size: 10px;
                }
            }
        }
        */

        input {
            font-size: 10px;
            font-weight: normal;
        }

    }


    .ag-floating-filter {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        display: inline-block;
    }

    .ag-floating-filter-body {
        margin-right: 25px;
        height: 20px;
    }

    .ag-floating-filter-full-body {
        width: 100%;
        height: 20px;
    }

    .ag-floating-filter-button {
        width: 25px;
        height: 20px;
        float: right;
        margin-top: -20px;
    }

    .ag-floating-filter-button button {
        width: 25px;
        height: 19px;
        padding: 0;
        border: none;
        background-color: #eee;
    }

    .ag-floating-filter-input {
        width: 100%;
    }

    .ag-floating-filter-input:read-only {
        background-color: #eee;
    }

    .ag-floating-filter-menu {
        position: absolute;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .ag-floating-filter-body input {
        width: 100%;
        margin: 0;
        height: 19px;
    }

    .ag-floating-filter-full-body input {
        width: 100%;
        margin: 0;
        height: 19px;
    }

    .ag-header-cell-moving .ag-header-cell-label {
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        filter: alpha(opacity=50);
        background-color: #eee;
        border: solid 1px #ddd;
    }

    .ag-header-cell-moving {
        background-color: #bebebe;
    }

    .ag-ltr .ag-header-group-cell {
        //border-right: none;
        border-right: 1px solid $cell-border-color;
    }

    .ag-rtl .ag-header-group-cell {
        border-left: none;
    }

    .ag-header-group-cell-with-group {
        //border-bottom: none;
        border-bottom: 1px solid $cell-border-color;
    }

    .ag-header-cell-label {
        padding: 2px;
        margin: 0;
        text-align: center;
    }

    .ag-header-cell-text {
        padding-left: 2px;
        //font-size: $cell-header-font-size;
    }

    .ag-header-group-cell-label {
        padding: 4px;
    }

    .ag-ltr .ag-header-group-cell-label {
        padding-left: 10px;
    }

    .ag-rtl .ag-header-group-cell-label {
        padding-right: 10px;
    }

    .ag-rtl .ag-header-group-text {
        margin-left: 2px;
    }

    .ag-ltr .ag-header-group-text {
        margin-right: 2px;
    }

    .ag-header-cell-menu-button {
        padding: 2px;
        margin-top: 1px;
        margin-left: 1px;
        margin-right: 1px;
        border: 1px solid $cell-border-color;
        border-radius: 3px;
        -webkit-box-sizing: content-box /* When using bootstrap, box-sizing was set to 'border-box' */
    ;
        -moz-box-sizing: content-box /* When using bootstrap, box-sizing was set to 'border-box' */
    ;
        box-sizing: content-box /* When using bootstrap, box-sizing was set to 'border-box' */
    ;
        line-height: 0px /* normal line height, a space was appearing below the menu button */
    ;
    }

    .ag-ltr .ag-pinned-right-header {
        //border-left: none;
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: $cell-border-color;
    }

    .ag-rtl .ag-pinned-left-header {
        border-right: none;
    }

    .ag-header-cell-menu-button:hover {
        border: 1px solid transparent;
    }

    .ag-body-viewport {
        background-color: $default-base-color;
    }

    .ag-row-odd {
        background-color: $row-odd-color;
    }

    .ag-row-even {
        background-color: $row-even-color;
    }

    .ag-row-selected {
        background-color: $row-highlighter-color;
    }


    .ag-row.ag-row-odd.ag-row-empty {
      background-color: $empty-odd-row-color;
      color: $empty-odd-row-color;

      .text-black {
        color: #000;
      }
    }

    .ag-row.ag-row-even.ag-row-empty {
      background-color: $empty-even-row-color;
      color: $empty-even-row-color;

      .text-black {
        color: #000;
      }
    }

    .ag-row.ag-row-odd.ag-row-group.ag-delta-row-empty {
      background-color: #e9e9e9;
      color: #e9e9e9;
    }

    .ag-row.ag-row-even.ag-row-group.ag-delta-row-empty {
      background-color: #fff;
      color: #fff;
    }

    .ag-floating-top {
        .ag-row {
            background-color: #f0f0f0;
        }

        .ag-cell {
            font-weight: bold;
        }
    }

    .ag-floating-bottom {
        .ag-row {
            background-color: #f0f0f0;
        }

        .ag-cell {
            font-weight: bold;

            span {
                font-weight: bold;
            }
        }
    }

    .ag-overlay-loading-wrapper {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .ag-overlay-loading-center {
        background-color: rgba(255, 255, 255, 0);
        border: none;
        border-radius: 10px;
        padding: 10px;
        color: #000;
    }

    .ag-overlay-no-rows-center {
        background-color: #fff;
        border: none;
        border-radius: 10px;
        padding: 20px;
    }

    .ag-group-cell-entire-row {
        background-color: $default-base-color;
        padding: 4px;
    }

    .ag-footer-cell-entire-row {
        background-color: $default-base-color;
        padding: 4px;
    }

    .ag-group-cell {
        font-style: italic;
    }

    .ag-ltr .ag-group-expanded {
        padding-right: 4px;
    }

    .ag-rtl .ag-group-expanded {
        padding-left: 4px;
    }

    .ag-ltr .ag-group-contracted {
        padding-right: 4px;
    }

    .ag-rtl .ag-group-contracted {
        padding-left: 4px;
    }

    .ag-ltr .ag-group-value {
        padding-right: 2px;
    }

    .ag-rtl .ag-group-value {
        padding-left: 2px;
    }

    .ag-ltr .ag-group-checkbox {
        padding-right: 2px;
    }

    .ag-rtl .ag-group-checkbox {
        padding-left: 2px;
    }

    .ag-group-child-count {
        display: inline-block;
    }

    .ag-footer-cell {
        font-style: italic;
    }

    .ag-menu {
        border: 1px solid $cell-border-color;
        background-color: $default-base-color;
        cursor: default;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .ag-menu .ag-tab-header {
        background-color: #e6e6e6;
    }

    .ag-menu .ag-tab {
        padding: 6px 8px 6px 8px;
        margin: 2px 2px 0px 2px;
        display: inline-block;
        border-right: 1px solid transparent;
        border-left: 1px solid transparent;
        border-top: 1px solid transparent;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
    }

    .ag-menu .ag-tab-selected {
        background-color: $default-base-color;
        border-right: 1px solid $cell-border-color;
        border-left: 1px solid $cell-border-color;
        border-top: 1px solid $cell-border-color;
    }

    .ag-menu-separator {
        border-top: 1px solid $cell-border-color;
    }

    .ag-menu-option-active {
        background-color: $row-highlighter-color;
    }

    .ag-menu-option-icon {
        padding: 2px 4px 2px 4px;
        vertical-align: middle;
    }

    .ag-menu-option-text {
        padding: 2px 4px 2px 4px;
        vertical-align: middle;
    }

    .ag-menu-option-shortcut {
        padding: 2px 2px 2px 2px;
        vertical-align: middle;
    }

    .ag-menu-option-popup-pointer {
        padding: 2px 4px 2px 4px;
        vertical-align: middle;
    }

    .ag-menu-option-disabled {
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        filter: alpha(opacity=50);
    }

    .ag-menu-column-select-wrapper {
        margin: 2px;
    }

    .ag-filter-checkbox {
        float: left;
        height: $virtual-item-height;
        margin-right: $grid-size;
        padding-top: $grid-size / 2;
    }

    .ag-filter-header-container {
        border-bottom: 1px solid $cell-border-color;
    }

    .ag-filter-apply-panel {
        border-top: 1px solid $cell-border-color;
        padding: 2px;
    }

    .ag-filter-value {
        margin-left: 4px;
    }

    .ag-ltr .ag-selection-checkbox {
        padding-right: 4px;
    }

    .ag-rtl .ag-selection-checkbox {
        padding-left: 4px;
    }

    .ag-paging-panel {
        padding: 4px;
        font-size: $cell-font-size;
        text-align: center;
    }

    .ag-paging-button {
        border: 1px solid $button-border-color;
        background-color: $paging-button-color;
        margin-left: 4px;
        margin-right: 4px;

        &:disabled {
            background-color: $paging-button-color-disabled;
        }

    }

    .ag-paging-row-summary-panel {
        display: inline-block;
        width: 100px;
        text-align: right;
        float: right;
    }

    .ag-paging-page-summary-panel {
        margin-left: 80px;
    }

    .ag-tool-panel {
        background-color: $default-base-color;
        border-bottom: none;
        border-top: none;
        color: #000;

        .ag-side-buttons {
            font-size: $cell-font-size;
        }
    }

    .ltr .ag-tool-panel {
        border-right: none;
    }

    .rtl .ag-tool-panel {
        border-left: none;
    }

    .ag-status-bar {
        color: #000;
        background-color: $default-base-color;
        font-size: $cell-font-size;
        height: 22px;
        border-bottom: none;
        border-left: none;
        border-right: none;
        padding: 2px;
    }

    .ag-status-bar-aggregations {
        float: right;
    }

    .ag-status-bar-item {
        padding-left: 10px;
    }

    .ag-column-drop-cell {
        background: none;
        color: #000;
        border: 1px solid #808080;
    }

    .ag-column-drop-cell-ghost {
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        filter: alpha(opacity=50);
    }

    .ag-column-drop-cell-text {
        padding-left: 2px;
        padding-right: 2px;
    }

    .ag-column-drop-cell-button {
        border: 1px solid transparent;
        padding-left: 2px;
        padding-right: 2px;
        border-radius: 3px;
    }

    .ag-column-drop-cell-button:hover {
        border: none;
    }

    .ag-column-drop-empty-message {
        padding-left: 2px;
        padding-right: 2px;
        color: #808080;
    }

    .ag-column-drop-icon {
        margin: 3px;
    }

    .ag-column-drop {
        background-color: $default-base-color;
    }

    .ag-column-drop-horizontal {
        padding: 2px;
        border-top: none;
        border-left: none;
        border-right: none;
    }

    .ag-column-drop-vertical {
        padding: 4px 4px 10px 4px;
        border-bottom: none;
    }

    .ag-column-drop-vertical .ag-column-drop-cell {
        margin-top: 2px;
    }

    .ag-column-drop-vertical .ag-column-drop-empty-message {
        text-align: center;
        padding: 5px;
    }

    .ag-pivot-mode {
        border-bottom: none;
        padding: 4px;
        background-color: $default-base-color;
    }

    .ag-tool-panel .ag-column-select-panel {
        border-bottom: none;
    }

    .ag-select-agg-func-popup {
        cursor: default;
        position: absolute;
        font-size: $cell-font-size;
        background-color: #fff;
        border: none;
    }

    .ag-select-agg-func-item {
        padding-left: 2px;
        padding-right: 2px;
    }

    .ag-select-agg-func-item:hover {
        background-color: $row-highlighter-color;
    }

    .ag-input-cell {
        // To give the ag grid input cell an input box on default TODO inherit the main input css
        height: 100%;
        width: 100%;
        background-color: #fff;
        -webkit-rtl-ordering: logical;
        cursor: text;
        padding: 1px;
        border-width: 1px;
        border-style: solid;
        border-color: #ddd;
        border-image: initial;
        line-height: 10px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    .ag-cell-inline-editing {
        padding: 1px;

        input {
            width: 100%;
            text-align: center;
            border: 1px solid cornflowerblue;
        }
    }

    .ag-dark-row {
        background-color: $dark-row-color
    }

    .ag-tall-header {
        text-overflow: ellipsis;
        overflow: visible;
        white-space: normal;
    }

    .ag-cell-focus {
        border: 2px solid #217346;
        padding: 1px;
    }

    .ag-tooltip {
        background-color: $tooltip-background-color;
        color: $tooltip-foreground-color;
        border-radius: $tooltip-border-radius;
        padding: $tooltip-padding;
        border-width: $tooltip-border-width;
        border-style: $tooltip-border-style;
        border-color: $tooltip-border-color;
        transition: opacity 1s;
        white-space: pre-line; // Allows line breaks (\n) in text to be recognized

        &.ag-tooltip-hiding {
            opacity: 0;
        }
    }
}
