/*
  Table of Contents
  ---------------------------------------------------------------

    - Main
    - Header
    - Content
    - Edit box
    - Timestamp
    - Helpers
    - Fullscreen
    - Styling
    - Custom styles
    - Icons - A few used examples
    - RTL support
    - Clearfix
    - Examples (minimalistic in grid)
    - Examples (lists)
*/

/*
 * MAIN
 */
article.sortable-grid { min-height:30px; }

.ybox {
    margin: 0 0 30px 0;
    position: relative;
    -moz-border-radius: 0;
    -khtml-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: 0;
}

.widget-content-padding {
    padding: 20px;
    .well { margin-bottom: 0; }
}

/*
 * HEADER
 */
.ybox > header {
    height: 34px; /* make sure ".ybox-loader" is also the same height or it will flicker during collapse */
    padding: 0;
    line-height: 40px;
    >:first-child.ybox-icon { margin-left:0; }

/*
    .ybox-icon {
        width: 16px;
        height: 16px;
        float: left;
        margin: 12px 10px 0 0;
        display: block;
        zoom: 1;
        z-index: 4000
    }
    */
    > .ybox-icon {
        display:block;
        width: 30px;
        height: 32px;
        position:relative;
        float:left;
        font-size: 111%;
        line-height:32px;
        text-align:center;
        margin-right:-10px;
    }
    > h2 {
        margin-left:10px !important;
        float:left;
    }
    h2 {
        height: 100%;
        width: auto;
        display:inline-block;
        font-size: 17px;
        position: relative;
        margin: 0;
        line-height: 34px;
        font-weight:normal;
        letter-spacing: 0;
    }
}

.ybox-ctrls {
    width: auto;
    float: right;
    padding: 0;
    margin: 0;
    .button-icon {
        min-width: 30px;
        height: 32px;
        float: left;
        position: relative;
        font-family: $font-family-sans-serif;
        border-left:1px solid rgba(0,0,0,0.09);
        &:hover {
            background-color:rgba(0,0,0,0.05);
        }
    }
}

.ybox-loader {
    width: 32px;
    height: 32px;
    margin: 0;
    float: right;
    background-repeat: no-repeat;
    background-position: center center;
    display: none;
    text-align: center;
    line-height: 32px;
    font-size: 111%;
}

.ybox-title-overflow {
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 90%;
    cursor: pointer;
}

.tooltip-inner {
    max-width: 1000px;
}

/*
 * CONTENT
 */
.ybox {
    > div {
        float: left;
        width: 100%;
        position: relative;
        font-size: 13px;
        -moz-border-radius: 0;
        -khtml-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
        margin: 0;
        border-width: 1px 1px 2px 1px;
        border-style: solid;
        border-top: none;
        border-right-color: #CCC !important;
        border-bottom-color: #CCC !important;
        border-left-color: #CCC !important;
        padding: 12px 12px 0;
        /* as per version 1.3 */
        overflow: visible;
    }
    .ybox-body {
        min-height:100px;
        position:relative;
        padding-bottom:13px;
        &.widget-hide-overflow { overflow:hidden; }
    }
}

.ybox-toolbar.no-border { border-left:none; }
.ybox-body.no-padding { margin:-13px -13px 0; }
.ybox.well.transparent .ybox-body.no-padding { margin:0 !important; }
.ybox-body > table { margin-bottom:0; }
.ybox-body.no-padding .alert {
    margin: 0 0 10px;
    padding: 10px;
    box-shadow:none !important;
    -webkit-box-shadow:none !important;
    -moz-box-shadow:none !important;
    border-width:0 0 1px !important;
    border-radius:0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
}

.ybox-body.no-padding .cke_chrome, .no-padding .md-editor { border:none; }
.ybox-toolbar > .smart-form { margin-top:2px; }
.ybox-toolbar > .smart-form .toggle:last-child {
    font-size:12px;
    line-height:29px;
}
.ybox-toolbar > .smart-form .radio input + i,
.ybox-toolbar > .smart-form .checkbox input + i,
.ybox-toolbar > .smart-form .toggle input + i {
    border-width:1px;
    border-color: #C7C7C7 !important;
    margin-top:1px;

    box-shadow: 0 1px 1px #FFF,0 1px 1px #858585 inset;
    -webkit-box-shadow: 0 1px 1px #FFF,0 1px 1px #858585 inset;
    -moz-box-shadow: 0 1px 1px #FFF,0 1px 1px #858585 inset;
}

/*
 * WELL WIDGET
 */
.ybox.well {
    margin: 0 0 30px 0;
    > div{
        border:none !important;
        box-shadow:none !important;
        -webkit-box-shadow:none !important;
        -moz-box-shadow:none !important;
    }
    header { display:none; }
}

/*
 * WIDGET FOOTER
 */

.ybox-footer {
    display: block;
    min-height: 32px;
    vertical-align:middle;
    position: relative;
    background-color: #F8F7F7;
    padding: 5px;
    border-top: 1px solid #E4E4E4;
    text-align:right;
    margin: 0 -13px -13px;
    .no-padding & { margin:0; }
    &.smart-form > label {
        margin-top:4px;
        display:block;
    }
}

/*
 * EDIT BOX
 */
.ybox-editbox {
    display: none;
    padding: 10px;
}
/*.ybox-editbox div {
width: 100%;
float: left;
margin-bottom: 0;
}
.ybox-editbox label {
width: 80px;
height: 26px;
float: left;
line-height: 26px;
font-weight: bold;
margin-bottom:0;
}
.ybox-editbox input {
width: 200px;
height: 24px;
padding: 0 5px;
border: 1px solid #666;
}
.ybox-editbox span {
height: 26px;
width: 26px;
float: left;
margin: 0 5px 0 0;
cursor: pointer;
}*/

/*
 * TIMESTAMP
 */
.ybox-timestamp {
    font-size: 12px;
    color: #868686;
    font-style:italic;
    margin: 10px 0 0;
}

/*
 * HELPERS
 */
.ybox-placeholder {
    -moz-border-radius: 0;
    -khtml-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    margin-bottom: 28px; /* adjusted from 28px */
    padding: 0;
    -webkit-box-sizing: border-box;
    -khtml-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

/*
 * WIDGET BODY TOOLBAR
 */
.ybox-body-toolbar, .smart-form.ybox-body-toolbar {
    display:block;
    padding:8px 10px;
    margin:-13px -13px 13px;
    min-height:42px;
    border-bottom:1px solid #ccc;
    background: #fafafa;
}

.no-padding.ybox-body-toolbar, .no-padding .ybox-body-toolbar, .no-padding .smart-form.ybox-body-toolbar {
    display: block;
    margin: 0;
}

.ybox-body-toolbar.smart-form .inline-group, .smart-form .ybox-body-toolbar .inline-group {
    float: left;
    margin-top: 4px;
}

.ybox-body-toolbar .btn { vertical-align: middle; }
.ybox-body-toolbar .btn-xs { margin-top: 5px; }

/*
 * STYLING
 */
.ybox {
    > header {
        color: #333;

        border: 1px solid #C2C2C2;
        background: #fafafa;
    }
    > div { background-color: #fff !important; }
}

.ybox-editbox {
    border-bottom: 1px solid #B1B1B1;
    background-color: #fff;
    margin: -13px -13px 13px;
    .no-padding & { margin: 0 0 10px; }
}
.ybox-placeholder {
    background-color: #FFC;
    border: 1px dashed #A7A7A7;
}

/*
 * CUSTOM STYLES
 */

/* remove colors */
.ybox-remove-colors {
    color:#333 !important;
    padding:0 !important;
    background:none !important;
}

/* orange theme */

.ybox-color-orange {
    > header {
        background: #C79121;
        color: #FFF;
        > .ybox-ctrls a {
            color: #fff !important;
        }
    }
    .nav-tabs li {
        &:not(.active) a {
            color: #fff !important;
        }
        a:hover {
            color: #333 !important;
        }
    }
    > header {
        border-color: #C79121 !important;
    }
}

/* orangeDark theme */

.ybox-color-orangeDark {
    > header {
        background: #A57225;
        color: #FFF;
        > .ybox-ctrls a {
            color: #fff !important;
        }
    }
    .nav-tabs li {
        &:not(.active) a {
            color: #fff !important;
        }
        a:hover {
            color: #333 !important;
        }
    }
    > header {
        border-color: #A57225 !important;
    }
}

/* white theme */

.ybox-color-white {
    > header {
        background: #fff;
        color: #838383;
        > .ybox-ctrls a {
            color: #838383 !important;
        }
    }
    .nav-tabs li {
        &:not(.active) a, a:hover {
            color: #838383 !important;
        }
    }
    > header {
        border-color: #C8C8C8 !important;
    }
}

/*
 * ICONS
 */
.ybox-ctrls a {
    text-decoration:none;
    font-size:14px;
    text-align:center;
    display:inline-block;
    line-height:32px;
    padding:0;
    margin:0;
    color:#333;
}


/*
 * CLEARFIX
 */
.ybox:before, .ybox:after, .ybox > div:before, .ybox > div:after, .inner-spacer:before, .inner-spacer:after, .ybox-editbox:before, .ybox-editbox:after, .clearfix:before, .clearfix:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    visibility: hidden;
}
.ybox:after, .ybox > div:after, .inner-spacer:after, .ybox-editbox:after, .clearfix:after { clear: both; }
.ybox, .ybox > div, .inner-spacer:after, .ybox-editbox, .clearfix { zoom: 1;/*IE*/ }

/*
 * WIDGET TOOLBARS
 */
.ybox-toolbar {
    display:inline-block;
    float:right;
    width: auto;
    height: 32px;
    line-height:32px;
    position: relative;
    border-left: 1px solid rgba(0, 0, 0, 0.09);
    cursor: pointer;
    padding: 0 8px;
    text-align:center;
    >:first-child { text-align:left; }
}

/*
 * WIDGET TOOLBAR COMPONENTS
 */

/*
 * TABS
 */
.ybox header .nav-tabs {
    border-bottom-color:transparent;
    > li {
        > a {
            border-radius: 0;
            border: none;
            padding: 7px 15px 6px;
        }
        &.active {
            > a, > a:hover, > a:focus {
                color: #555;
                background-color: #FFF ;
                border: 1px solid #C2C2C2;
                border-bottom-color: transparent;
                border-top:none;
                cursor: default;
            }
        }
    }
}

.ybox header:first-child .nav-tabs {
    float:left;
    li {
        padding-left:0;
        a {
            color:#333;
        }
    }
    &.pull-right li:last-child a {
        margin-right:0 !important;
        border-right:none !important;
    }
}

/*
 * PILLS
 */
.ybox header .nav-pills {
    margin:3px;
    > li > a { padding: 3px 5px 4px; }
}

/*
 * BTN GROUPS
 */
.ybox-toolbar .btn-group { margin-top:-3px; }

/*
 * BTNs
 */
.ybox-toolbar > .btn {
    margin-top:-3px;
    font-size:12px !important;
    padding: 1px 8px !important;
}

/*
 * INPUT
 */
.ybox-toolbar .smart-form label.checkbox, .ybox-toolbar .smart-form label.radio { line-height:29px; }

/*
 * LABELS
 */
.ybox-toolbar > .label {
    vertical-align: middle;
    margin-top: -3px;
    display: inline-block;
    text-align:center;
    font-size:12px;
    padding: 4px 7px;
}

/*
 * BADGE
 */
.ybox-toolbar > .badge {
    padding: 5px 5px;
    font-size: 14px;
    border-radius: 50%;
    font-weight:normal;
    min-width:24px;
    text-align: center !important;
}

/*
 * PROGRESS BARS
 */
.ybox-toolbar .progress {
    width:130px;
    margin: 7px 0 0 0;
    height: 18px !important;
    font-size:12px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0), 0 0 0 1px #D1D1D1 inset;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0), 0 0 0 1px #D1D1D1 inset;
    -moz-box-shadow: 0 1px 0 transparent, 0 0 0 1px #D1D1D1 inset
}

.ybox-toolbar .progress-bar { font-size:12px; }

/*
 * PAGINATION
 */
.ybox-toolbar .pagination {
    margin:4px 0;
    > li > a { padding: 2px 7px; }
}

/*
 * TEXT INPUT
 */
.ybox-toolbar .widget-text-input { max-width:220px; }
.ybox-toolbar input[type='text'] {
    height:28px !important;
    margin-top:2px;
}
.ybox-toolbar.smart-form .icon-append, .ybox-toolbar.smart-form .icon-prepend,
.ybox-toolbar .smart-form .icon-append, .ybox-toolbar .smart-form .icon-prepend  {
    top:3px !important;
}

