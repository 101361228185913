.row.no-gutter {
    margin-left: 0;
    margin-right: 0;
}

.row.no-gutter [class*='col-']:not(:first-child),
.row.no-gutter [class*='col-']:not(:last-child) {
    padding-right: 0;
    padding-left: 0;
}

.d-none {
  display: none !important;
}
