.log-modal {
    .id-box {
        height: 150px;
        overflow: auto;
    }
}

.edit-conversion-logs-modal {
    .modal-stepper {
        padding-left: 39%;

        .edit-step {
            margin-right: -12px;
        }

        .step-divider {
            width: 50px;
            height: 32px;
            border-top: 1px solid #ddd;
        }

        .confirm-step {
            margin-left: -12px;
        }
    }
}

.modal-flex-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.modal-close-btn {
    padding: 1px 10px;
}

.modal-body-section {
  margin-bottom: 20px;
  color: #212427;

  h4 {
    font-size: 16px;
    color: #616161;
    margin-bottom: 5px;
  }
}
