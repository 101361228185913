textarea {
    &.no-resize {
        resize: none;
    }
    &.v-resize-only {
        resize: vertical;
        overflow: auto;
    }
    &.h-resize-only {
        resize: horizontal;
        overflow: auto;
    }
    max-width: 100%;
}
