.hidden {
    visibility: hidden;
    display: none;
}

.no-gutter {
    padding-right:0;
    padding-left:0;
}

.no-gutter-left {
    padding-left:0;
}

.no-gutter-right {
    padding-right:0;
}

.row.no-gutters {
    margin-right: 0;
    margin-left: 0;

    & > [class^="col-"],
    & > [class*=" col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.col-xs-no-gutter {
    padding-right: 0px;
    padding-left: 0px;
}

.col-sm-no-gutter {
    padding-right: 0px;
    padding-left: 0px;
}

.col-md-no-gutter {
    padding-right: 0px;
    padding-left: 0px;
}

.col-lg-no-gutter {
    padding-right: 0px;
    padding-left: 0px;
}

.col-xl-no-gutter {
    padding-right: 0px;
    padding-left: 0px;
}

.visible-xs-inline, .visible-sm-inline, .visible-md-inline, .visible-lg-inline, .visible-xl-inline   {
    visibility: visible;
    display: inline;
}

.hidden-xs-inline, .hidden-sm-inline, .hidden-md-inline, .hidden-lg-inline, .hidden-xl-inline {
    visibility: hidden;
    display: none;
}

/* effective at col-xs */
@media (min-width: 480px) {
    .col-xs-no-gutter {
        padding-right: 15px;
        padding-left: 15px;
    }
}

/* effective at col-sm */
@media (min-width: 768px) {
    .col-sm-no-gutter {
        padding-right: 15px;
        padding-left: 15px;
    }

    .hidden-xs-inline {
        visibility: visible;
        display: inline;
    }

    .visible-xs-inline {
        visibility: hidden;
        display: none;
    }
}

/* effective at col-md */
@media (min-width: 992px) {
    .col-md-no-gutter {
        padding-right: 15px;
        padding-left: 15px;
    }

    .hidden-sm-inline {
        visibility: visible;
        display: inline;
    }

    .visible-sm-inline {
      visibility: hidden;
      display: none;
    }
}

/* effective at col-lg */
@media (min-width: 1200px) {
    .col-lg-no-gutter {
        padding-right: 15px;
        padding-left: 15px;
    }

    .hidden-md-inline {
        visibility: visible;
        display: inline;
    }

    .visible-md-inline {
      visibility: hidden;
      display: none;
    }
}

@media (min-width: 1600px) {
    .col-xl-no-gutter {
        padding-right: 15px;
        padding-left: 15px;
    }

    .hidden-lg-inline {
        visibility: visible;
        display: inline;
    }

    .visible-lg-inline {
      visibility: hidden;
      display: none;
    }
}
