/* tabs override */
.nav-tabs {
    li.tab-control.disabled {
        > a {
            &:hover {
                border-color: transparent;
                text-decoration: none;
                background-color: transparent;
            }
        }
    }
    li.tab-control.disabled.tab-control-enabled {
        > a {
            color: #333;

            &:hover {
                color: lighten(#333, 25% );
                cursor: pointer;
            }
        }
    }
}

.uib-timepicker button.disabled {
    cursor: not-allowed;
    background-color: #eee;
    opacity: 1;
}

/* ag-grid stats pivot dropdown */
.grid-pivot-dropdown {
    &.dropdown-menu {
        left: -22px;
        min-width: 50px;
        font-size: 10px;

        .btn {
            &.disabled,
            &[disabled],
            fieldset[disabled] & {
                &,
                &:hover,
                &:focus,
                &:active,
                &.active {
                    background-color: #d9d9d9;
                }
              }
        }

    }
}
