/*
Reference from yd-variables:

$width-none: 0;
$width-xxs: 5px;
$width-xs: 6px;
$width-sm: 10px;
$width-def: 13px;
$width-md: 20px;
$width-lg: 30px;
$width-xl: 40px;
*/

/* Padding Surround */

.p-none {
    padding: $width-none;
}

.p-xxs {
    padding: $width-xxs;
}

.p-xs {
    padding: $width-xs;
}

.p-sm {
    padding: $width-sm;
}

.p {
    padding: $width-def;
}

.p-md {
    padding: $width-md;
}

.p-lg {
    padding: $width-lg;
}

.p-xl {
    padding: $width-xl;
}

/* Padding Left Right */

.p-lr-none {
    padding-left: $width-none;
    padding-right: $width-none;
}

.p-lr-xxs {
    padding-left: $width-xxs;
    padding-right: $width-xxs;
}

.p-lr-xs {
    padding-left: $width-xs;
    padding-right: $width-xs;
}

.p-lr-sm {
    padding-left: $width-sm;
    padding-right: $width-sm;
}

.p-lr {
    padding-left: $width-def;
    padding-right: $width-def;
}

.p-lr-m {
    padding-left: $width-md;
    padding-right: $width-md;
}

.p-lr-lg {
    padding-left: $width-lg;
    padding-right: $width-lg;
}

.p-lr-lg {
    padding-left: $width-xl;
    padding-right: $width-xl;
}

/* Padding Top Bottom */

.p-tb-none {
    padding-top: $width-none;
    padding-bottom: $width-none;
}

.p-tb-xxs {
    padding-top: $width-xxs;
    padding-bottom: $width-xxs;
}

.p-tb-xs {
    padding-top: $width-xs;
    padding-bottom: $width-xs;
}

.p-tb-sm {
    padding-top: $width-sm;
    padding-bottom: $width-sm;
}

.p-tb {
    padding-top: $width-def;
    padding-bottom: $width-def;
}

.p-tb-m {
    padding-top: $width-md;
    padding-bottom: $width-md;
}

.p-tb-lg {
    padding-top: $width-lg;
    padding-bottom: $width-lg;
}

.p-tb-lg {
    padding-top: $width-xl;
    padding-bottom: $width-xl;
}

/* Padding Left */

.p-l-none {
    padding-left: $width-none;
}

.p-l-xs {
    padding-left: $width-xxs;
}

.p-l-sm {
    padding-left: $width-xs;
}

.p-l {
    padding-left: $width-sm;
}

.p-l-md {
    padding-left: $width-def;
}

.p-l-lg {
    padding-left: $width-lg;
}

.p-l-xl {
    padding-left: $width-xl;
}

.p-l-n-xxs {
    padding-left: (-$width-xxs);
}

.p-l-n-xs {
    padding-left: (-$width-xs);
}

.p-l-n-sm {
    padding-left: (-$width-sm);
}

.p-l-n {
    padding-left: (-$width-def);
}

.p-l-n-md {
    padding-left: (-$width-md);
}

.p-l-n-lg {
    padding-left: (-$width-lg);
}

.p-l-n-xl {
    padding-left: (-$width-xl);
}

/* Padding Right */

.p-r-none {
    padding-right: $width-none;
}

.p-r-xs {
    padding-right: $width-xxs;
}

.p-r-sm {
    padding-right: $width-xs;
}

.p-r {
    padding-right: $width-sm;
}

.p-r-md {
    padding-right: $width-def;
}

.p-r-lg {
    padding-right: $width-lg;
}

.p-r-xl {
    padding-right: $width-xl;
}

.p-r-n-xxs {
    padding-right: (-$width-xxs);
}

.p-r-n-xs {
    padding-right: (-$width-xs);
}

.p-r-n-sm {
    padding-right: (-$width-sm);
}

.p-r-n {
    padding-right: (-$width-def);
}

.p-r-n-md {
    padding-right: (-$width-md);
}

.p-r-n-lg {
    padding-right: (-$width-lg);
}

.p-r-n-xl {
    padding-right: (-$width-xl);
}

/* Padding Top */

.p-t-none {
    padding-top: $width-none;
}

.p-t-xs {
    padding-top: $width-xxs;
}

.p-t-sm {
    padding-top: $width-xs;
}

.p-t {
    padding-top: $width-sm;
}

.p-t-md {
    padding-top: $width-def;
}

.p-t-lg {
    padding-top: $width-lg;
}

.p-t-xl {
    padding-top: $width-xl;
}

.p-t-n-xxs {
    padding-top: (-$width-xxs);
}

.p-t-n-xs {
    padding-top: (-$width-xs);
}

.p-t-n-sm {
    padding-top: (-$width-sm);
}

.p-t-n {
    padding-top: (-$width-def);
}

.p-t-n-md {
    padding-top: (-$width-md);
}

.p-t-n-lg {
    padding-top: (-$width-lg);
}

.p-t-n-xl {
    padding-top: (-$width-xl);
}

/* Padding Bottom */

.p-b-none {
    padding-bottom: $width-none;
}

.p-b-xs {
    padding-bottom: $width-xxs;
}

.p-b-sm {
    padding-bottom: $width-xs;
}

.p-b {
    padding-bottom: $width-sm;
}

.p-b-md {
    padding-bottom: $width-def;
}

.p-b-lg {
    padding-bottom: $width-lg;
}

.p-b-xl {
    padding-bottom: $width-xl;
}

.p-b-n-xxs {
    padding-bottom: (-$width-xxs);
}

.p-b-n-xs {
    padding-bottom: (-$width-xs);
}

.p-b-n-sm {
    padding-bottom: (-$width-sm);
}

.p-b-n {
    padding-bottom: (-$width-def);
}

.p-b-n-md {
    padding-bottom: (-$width-md);
}

.p-b-n-lg {
    padding-bottom: (-$width-lg);
}

.p-b-n-xl {
    padding-bottom: (-$width-xl);
}

/* Margin Surround */

.m-none {
    margin: $width-none;
}

.m-xxs {
    margin: $width-xxs;
}

.m-xs {
    margin: $width-xs;
}

.m-sm {
    margin: $width-sm;
}

.m {
    margin: $width-def;
}

.m-md {
    margin: $width-md;
}

.m-lg {
    margin: $width-lg;
}

.m-xl {
    margin: $width-xl;
}

/* Margin Left Right */

.m-lr-none {
    margin-left: $width-none;
    margin-right: $width-none;
}

.m-lr-xxs {
    margin-left: $width-xxs;
    margin-right: $width-xxs;
}

.m-lr-xs {
    margin-left: $width-xs;
    margin-right: $width-xs;
}

.m-lr-sm {
    margin-left: $width-sm;
    margin-right: $width-sm;
}

.m-lr {
    margin-left: $width-def;
    margin-right: $width-def;
}

.m-lr-m {
    margin-left: $width-md;
    margin-right: $width-md;
}

.m-lr-lg {
    margin-left: $width-lg;
    margin-right: $width-lg;
}

.m-lr-lg {
    margin-left: $width-xl;
    margin-right: $width-xl;
}

/* Margin Left Right */

.m-tb-none {
    margin-top: $width-none;
    margin-bottom: $width-none;
}

.m-tb-xxs {
    margin-top: $width-xxs;
    margin-bottom: $width-xxs;
}

.m-tb-xs {
    margin-top: $width-xs;
    margin-bottom: $width-xs;
}

.m-tb-sm {
    margin-top: $width-sm;
    margin-bottom: $width-sm;
}

.m-tb {
    margin-top: $width-def;
    margin-bottom: $width-def;
}

.m-tb-m {
    margin-top: $width-md;
    margin-bottom: $width-md;
}

.m-tb-lg {
    margin-top: $width-lg;
    margin-bottom: $width-lg;
}

.m-tb-xl {
    margin-top: $width-xl;
    margin-bottom: $width-xl;
}

/* Margin Left */

.m-l-none {
    margin-left: $width-none;
}

.m-l-xs {
    margin-left: $width-xxs;
}

.m-l-sm {
    margin-left: $width-xs;
}

.m-l {
    margin-left: $width-sm;
}

.m-l-md {
    margin-left: $width-def;
}

.m-l-lg {
    margin-left: $width-lg;
}

.m-l-xl {
    margin-left: $width-xl;
}

.m-l-n-xxs {
    margin-left: (-$width-xxs);
}

.m-l-n-xs {
    margin-left: (-$width-xs);
}

.m-l-n-sm {
    margin-left: (-$width-sm);
}

.m-l-n {
    margin-left: (-$width-def);
}

.m-l-n-md {
    margin-left: (-$width-md);
}

.m-l-n-lg {
    margin-left: (-$width-lg);
}

.m-l-n-xl {
    margin-left: (-$width-xl);
}

/* Margin Right */

.m-r-none {
    margin-right: $width-none;
}

.m-r-xs {
    margin-right: $width-xxs;
}

.m-r-sm {
    margin-right: $width-xs;
}

.m-r {
    margin-right: $width-sm;
}

.m-r-md {
    margin-right: $width-def;
}

.m-r-lg {
    margin-right: $width-lg;
}

.m-r-xl {
    margin-right: $width-xl;
}

.m-r-n-xxs {
    margin-right: (-$width-xxs);
}

.m-r-n-xs {
    margin-right: (-$width-xs);
}

.m-r-n-sm {
    margin-right: (-$width-sm);
}

.m-r-n {
    margin-right: (-$width-def);
}

.m-r-n-md {
    margin-right: (-$width-md);
}

.m-r-n-lg {
    margin-right: (-$width-lg);
}

.m-r-n-xl {
    margin-right: (-$width-xl);
}

/* Margin Top */

.m-t-none {
    margin-top: $width-none;
}

.m-t-xs {
    margin-top: $width-xxs;
}

.m-t-sm {
    margin-top: $width-xs;
}

.m-t {
    margin-top: $width-sm;
}

.m-t-md {
    margin-top: $width-def;
}

.m-t-lg {
    margin-top: $width-lg;
}

.m-t-xl {
    margin-top: $width-xl;
}

.m-t-n-xxs {
    margin-top: (-$width-xxs);
}

.m-t-n-xs {
    margin-top: (-$width-xs);
}

.m-t-n-sm {
    margin-top: (-$width-sm);
}

.m-t-n {
    margin-top: (-$width-def);
}

.m-t-n-md {
    margin-top: (-$width-md);
}

.m-t-n-lg {
    margin-top: (-$width-lg);
}

.m-t-n-xl {
    margin-top: (-$width-xl);
}

/* Margin Bottom */

.m-b-none {
    margin-bottom: $width-none;
}

.m-b-xs {
    margin-bottom: $width-xxs;
}

.m-b-sm {
    margin-bottom: $width-xs;
}

.m-b {
    margin-bottom: $width-sm;
}

.m-b-md {
    margin-bottom: $width-def;
}

.m-b-lg {
    margin-bottom: $width-lg;
}

.m-b-xl {
    margin-bottom: $width-xl;
}

.m-b-200 {
    margin-bottom: 200px;
}

.m-b-500 {
    margin-bottom: 500px;
}

.m-b-n-xxs {
    margin-bottom: (-$width-xxs);
}

.m-b-n-xs {
    margin-bottom: (-$width-xs);
}

.m-b-n-sm {
    margin-bottom: (-$width-sm);
}

.m-b-n {
    margin-bottom: (-$width-def);
}

.m-b-n-md {
    margin-bottom: (-$width-md);
}

.m-b-n-lg {
    margin-bottom: (-$width-lg);
}

.m-b-n-xl {
    margin-bottom: (-$width-xl);
}
