.well-panel {
    .btn {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    .radio + .radio, .checkbox + .checkbox { margin-top: 0; }
    footer {
        .btn {
            float: right;
            height: 31px;
            margin: 10px 0 0 5px;
            padding: 0 22px;
            font: 300 15px/29px $font-family-sans-serif;
            cursor: pointer;
        }
        display: block;
        padding: 7px 14px 15px;
        border-top: 1px solid rgba(0,0,0,.1);
        background: rgba(248,248,248,.9);
        min-height: 55px;

        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }
    legend { padding-top: 15px; }

    margin: 0;
    outline: none;
    color: #666;
    position: relative;

    header {
        display: block;

        border-bottom: 1px dashed rgba(0, 0, 0, .2);
        background: white;
        font-size: 16px;
        font-weight: 300;
        color: #232323;

        min-height: 55px;
        padding: 15px 13px;
        margin:0;
        border-bottom-style:solid;
        border-bottom-color:rgba(red($black), green($black), blue($black), .1);
        background: rgba(248, 248, 248, .9);

    }
    fieldset {
        display: block;
        padding: 25px 14px 5px;
        border: none;
        background: rgba(255,255,255,.9);
        position: relative;

        + fieldset { border-top: 1px solid rgba(0,0,0,.1); }
    }
    section {
        margin-bottom: 15px;
        position: relative;
    }

    .label {
        display: block;
        margin-bottom: 6px;
        line-height: 19px;
        font-weight: 400;
        font-size: 13px;
        color: #333;
        text-align: left;
        white-space: normal;
        & .col {
            margin: 0;
            padding-top: 7px;
        }
    }
    .input, .select, .textarea, .radio, .checkbox, .toggle, .button {
        position: relative;
        display: block;
        font-weight: 400;
    }
    .input input, .select select, .textarea textarea {
        display: block;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        width: 100%;
        height: 32px;
        line-height: 32px;
        padding: 5px 10px;
        outline: none;
        border-width: 1px;
        border-style: solid;
        border-radius: 0;
        background: #fff;
        font: 13px/16px $font-family-sans-serif;
        color: #404040;
        appearance: normal;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
}
