$approval-state-not-started-color: #A3A3A3;
$approval-state-pending-color: #F9B65D;
$approval-state-approved-color: #0CB9ED;
$approval-state-denied-color: #DA2B3A;

// COLORS =====
.cds-not-started-color {
  color: $approval-state-not-started-color;
}

.cds-pending-color {
  color: $approval-state-pending-color;
}

.cds-approved-color {
  color: $approval-state-approved-color;
}

.cds-denied-color {
  color: $approval-state-denied-color;
}

// DIV - Labels =====
.status-display-not-started {
  background-color: $approval-state-not-started-color;
  border-color: darken($approval-state-not-started-color, 5%);
  color: white;
}

.status-display-pending {
  background-color: $approval-state-pending-color;
  border-color: darken($approval-state-pending-color, 5%);
  color: white;
}

.status-display-approved {
  background-color: $approval-state-approved-color;
  border-color: darken($approval-state-approved-color, 5%);
  color: white;
}

.status-display-denied {
  background-color: $approval-state-denied-color;
  border-color: darken($approval-state-denied-color, 5%);
  color: white;
}
