.card, .card-contact {
    position: relative;
    margin-bottom: $line-height-computed;
    border: 1px solid $panel-default-border;
    border-radius: $border-radius-base;
}

.card-block, .card-body {
    padding: $panel-body-padding;
}

.card-title {
    margin-top: 0;
    margin-bottom: .5em;
}

.card-subtitle {
    margin-top: 0;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link {
    text-decoration: none;

    + .card-link {
        margin-left: ($grid-gutter-width / 2);
    }
}

.card {
    > .list-group:first-child {
        .list-group-item:first-child {
            border-radius: $border-radius-base $border-radius-base 0 0;
        }
    }

    > .list-group:last-child {
        .list-group-item:last-child {
            border-radius: 0 0 $border-radius-base $border-radius-base;
        }
    }
}


//
// Optional textual caps
//

.card-header {
    padding: $panel-body-padding;
    background-color: $panel-default-heading-bg;
    border-bottom: 1px solid $panel-default-border;

    &:first-child {
        border-radius: ($border-radius-base - 1) ($border-radius-base - 1) 0 0;
    }
}

.card-footer {
    padding: $panel-body-padding;
    background-color: $panel-footer-bg;
    border-top: 1px solid $panel-default-border;

    &:last-child {
        border-radius: 0 0 ($panel-border-radius - 1) ($panel-border-radius - 1);
    }
}


//
// Background variations
//

.card-primary {
    background-color: $brand-primary;
    border-color: $brand-primary;

    $this-background-color: $brand-primary;
    $this-border-color: $brand-primary;

    .card-header {
        background-color: $this-background-color;
        border-bottom: 1px solid $this-border-color;
    }

    .card-footer {
        background-color: $this-background-color;
        border-top: 1px solid $this-border-color;
    }
}
.card-success {
    background-color: $brand-success;
    border-color: $brand-success;

    $this-background-color: $brand-success;
    $this-border-color: $brand-success;

    .card-header {
        background-color: $this-background-color;
        border-bottom: 1px solid $this-border-color;
    }

    .card-footer {
        background-color: $this-background-color;
        border-top: 1px solid $this-border-color;
    }
}
.card-info {
    background-color: $brand-info;
    border-color: $brand-info;

    $this-background-color: $brand-info;
    $this-border-color: $brand-info;

    .card-header {
        background-color: $this-background-color;
        border-bottom: 1px solid $this-border-color;
    }

    .card-footer {
        background-color: $this-background-color;
        border-top: 1px solid $this-border-color;
    }
}
.card-warning {
    background-color: $brand-warning;
    border-color: $brand-warning;

    $this-background-color: $brand-warning;
    $this-border-color: $brand-warning;

    .card-header {
        background-color: $this-background-color;
        border-bottom: 1px solid $this-border-color;
    }

    .card-footer {
        background-color: $this-background-color;
        border-top: 1px solid $this-border-color;
    }
}
.card-danger {
    background-color: $brand-danger;
    border-color: $brand-danger;

    $this-background-color: $brand-danger;
    $this-border-color: $brand-danger;

    .card-header {
        background-color: $this-background-color;
        border-bottom: 1px solid $this-border-color;
    }

    .card-footer {
        background-color: $this-background-color;
        border-top: 1px solid $this-border-color;
    }
}

.card-white {
    background-color: white;
    border-color: $panel-default-border;

    $this-background-color: white;
    $this-border-color: $panel-default-border;

    .card-header {
        background-color: $this-background-color;
        border-bottom: 1px solid $this-border-color;
    }

    .card-footer {
        background-color: $this-background-color;
        border-top: 1px solid $this-border-color;
    }
}

//
// Inverse text within a card for use with dark backgrounds
//

.card-inverse {
    .card-header,
    .card-footer {
        border-bottom: 1px solid rgba(255,255,255,.2);
    }
    .card-header,
    .card-footer,
    .card-title,
    .card-blockquote {
        color: #fff;
    }
    .card-link,
    .card-text,
    .card-blockquote > footer {
        color: rgba(255,255,255,.65);
    }
    .card-link {
        &:hover,
        &:focus {
            color: #fff;
        }
    }
    &.card-header,
    &.card-footer,
    &.card-title,
    &.card-blockquote {
        color: #fff;
    }
}


//
// Blockquote
//

.card-blockquote {
    padding: 0;
    margin-bottom: 0;
    border-left: 0;
}

// Card image
.card-img {
    border-radius: $panel-border-radius;
}
.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $panel-body-padding;
}



// Card image caps
.card-img-top {
    border-radius: $panel-border-radius $panel-border-radius 0 0;
}
.card-img-bottom {
    border-radius: 0 0 $panel-border-radius $panel-border-radius;
}


//
// Card set
//

.card-deck {
    display: table;
    table-layout: fixed;
    border-spacing: ($grid-gutter-width / 2) 0;

    .card {
        display: table-cell;
        width: 1%;
        vertical-align: top;
    }
}
.card-deck-wrapper {
    margin-right: -($grid-gutter-width / 2);
    margin-left: -($grid-gutter-width / 2);
}

//
// Card groups
//

.card-group {
    display: table;
    width: 100%;
    table-layout: fixed;

    .card {
        display: table-cell;
        vertical-align: top;

        + .card {
            margin-left: 0;
            border-left: 0;
        }

        // Handle rounded corners
        &:first-child {
            .card-img-top {
                border-top-right-radius: 0;
            }
            .card-img-bottom {
                border-bottom-right-radius: 0;
            }
        }
        &:last-child {
            .card-img-top {
                border-top-left-radius: 0;
            }
            .card-img-bottom {
                border-bottom-left-radius: 0;
            }
        }

        &:not(:first-child):not(:last-child) {
            border-radius: 0;

            .card-img-top,
            .card-img-bottom {
                border-radius: 0;
            }
        }
    }
}


//
// Card
//

.card-columns {
    column-count: 3;
    column-gap: ($grid-gutter-width / 2);

    .card {
        display: inline-block;
        width: 100%; // Don't let them exceed the column width
    }
}
