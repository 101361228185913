/* MIN VIEW SUPPORTED */

body {
    min-width: 320px;
    min-height: 100vh;
}

p, a {
    word-wrap: break-word;
}

.inline-block {
    display: inline-block;
}

.bg-color-lightgray {
    background-color: lighten(#e6ecf0, 2%) !important;
}

.spinner-color {
    color: $yd-orange;
}

.text-area-yd {
    resize: none;
}

.vis-hidden {
    visibility: hidden;
}

.reset-height {
    height: inherit;
}

// FIX OVERFLOW OF BUTTON TEXT
.btn-overflow, .text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.uppercase {
    text-transform: uppercase;
}

// Hijack styling
#hijacked-warning {
    text-align: center;

    // hide hijack warning dismissal
    .hijacked-warning-controls button.close {
        display: none;
    }
}

// collapse-button
.collapse-button-styles {
    font-size: 75%;
}

// Fix checkbox oversized height from form-control
input[type=checkbox].form-control {
    height: 14px;
}

// reset width to default (useful to include w/ form-control)
.default-width {
    width: auto;
}

.full-width {
    width: 100%;
}

.container-table {
    height: 100%;
    display: table;
    width: 100%;
    padding: 0;
}

.row-table {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}

.border-none {
    border: 0px;
}

.border-t-none {
    border-top: 0;
}

.border-b-none {
    border-bottom: 0;
}

.border-l-none {
    border-left: 0;
}

.border-r-none {
    border-right: 0;
}

.border-xs {
    border: 1px solid black;
}

.border-sm {
    border: 2px solid black;
}

.min-width-xs {
    min-width: 50px;
}

.min-width-sm {
    min-width: 100px;
}

.min-width-md {
    min-width: 150px;
}

.min-width-lg {
    min-width: 200px;
}

.min-width-cap-form {
    min-width: 400px;
}

.table-layout-fixed {
  table-layout:fixed;
}

.form-group .help-block {
    display: none;
}

.form-group.has-warning .help-block,
.form-group.has-success .help-block,
.form-group.has-error .help-block {
    display: block;
}

.text-capitalize, .affiliate-signup-errors li {
    text-transform: capitalize;
}

.fa-disabled {
    opacity: 0.25;
    color: gray !important;

    &:hover {
        opacity: 0.25;
        color: gray !important;
    }
}

.bs-typeahead .dropdown-menu {
    max-height: 150px;
    overflow: auto;
    width: 90%;
}

#logo-group {
    width: 30px !important;
    height: 30px !important;
    span {
        max-width: 100% !important;
        max-height: 100% !important;
        img {
            max-width: 100% !important;
            max-height: 100% !important;
        }
    }
}

.text-white {
    color: #fff;
}

.text-primary-lite {
    color: saturate($brand-primary, 25%);
}

.text-success-lite {
    color: saturate($brand-success, 25%);
}

.text-info-lite {
    color: saturate($brand-info, 25%);
}

.text-warning-lite {
    color: saturate($brand-warning, 25%);
}

.text-danger-lite {
    color: saturate($brand-danger, 25%);
}

.text-dark-lite {
    color: #000000;
}

.strikethrough {
    text-decoration: line-through;
}

// Dashboard
.news-preview {
    overflow-wrap: break-word;
}

.news-form-header {
    margin-bottom: 25px;
    text-align: center;
}

.jumbotron .news-preview-p {
    font-size: 12px;
}

.pre-wrap {
    word-break: keep-all;
    word-wrap: break-word;
    white-space: pre;
    white-space: -moz-pre-wrap;
    white-space: pre-wrap;
    white-space: pre\9;
}

.hl-sticky {
    background-color: white;
    padding: 20px 15px 20px;
    border-top: 1px solid #ccc;
}

.normal-line-height {
    line-height: normal;
}

/* add to pre tag to match form-control when empty */
.height-fc {
    min-height: 32px;
}

/* update pre bg to match bs input-disabled color */
pre {
    background-color: $input-bg-disabled;
    margin-bottom: 0;
    font-family: inherit;
    font-size: 13px;
    padding: 5px 10px;
}

.vcenter-sm {
	display: inline-block;
	vertical-align: middle;
	float: none;
	line-height: 30px;
}

.vcenter {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.strong {
  font-weight: bold;
}

label.required, a.required {
	color: #420000;
	font-weight: 700;

    &:after {
        content: "*";
    }
}

label.not-required, a.not-required {
	color: #212529;
	font-weight: 700;
}

label.fulfilled, a.fulfilled{
	color: #004200 !important;
	font-weight: 700;
}

label.readonly {
	color: #a8a8a8;
}

.adjust-sm {
	width: 34px;
	margin: 0px;
	padding: 0px;
}

.adjust-full {
	width: 100%;
}

.adjust-color {
	background-color: CornflowerBlue;
	color: white;
}

.lowercase, .text-lowercase {
	text-transform: lowercase;
}

.uppercase, .text-lowercase {
	text-transform: uppercase;
}

.system-title, .capitalize, .text-capitalize {
	text-transform: capitalize;
}

.font-weight-bold {
    font-weight: bold;
}

.font-weight-normal {
    font-weight: normal;
}

.font-italic {
    font-style: italic;
}

.date-height {
	height: 98px;
	margin-bottom: 0px;
}

.margin-date-height {
	margin-top: 33.3px;
	margin-bottom: 0px;
}

.date-label {
	@media screen and (min-width: $break-xs) {
		height: 98px;
		line-height: 98px;
		white-space: nowrap;
	}
}

/* tab content margin */
.tab-margin .tab-content {
    margin-top: 10px;
}

/* min-height classes */
.min-height-100 {
    min-height: 100px;
}

.min-height-200 {
    min-height: 200px;
}

.min-height-300 {
    min-height: 300px;
}

.min-height-350 {
    min-height: 350px;
}

.min-height-500 {
    min-height: 500px;
}

.tr-alert-warning {
  background-size: 50px 50px;
  background-color: #f2dede;
  color: #000;
  background-image: -webkit-linear-gradient(-45deg, rgba(0, 0, 0, .04) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .04) 50%, rgba(0, 0, 0, .04) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(-45deg, rgba(0, 0, 0, .04) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .04) 50%, rgba(0, 0, 0, .04) 75%, transparent 75%, transparent);
  background-image: linear-gradient(135deg, rgba(0, 0, 0, .04) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .04) 50%, rgba(0, 0, 0, .04) 75%, transparent 75%, transparent);
  margin-bottom:0;
  z-index: 1000000;
  font-weight: 600;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 5px 7px;
  border-bottom: 2px solid #ebcccc;
  font-size: 12px;
  font-family: sans-serif;
}

/* Custom font sizes */
.font-size-75 {
    font-size: 75%;
}

.font-size-80 {
    font-size: 80%;
}

.font-size-90 {
    font-size: 90%;
}

.font-size-100 {
    font-size: 100%;
}

/* Custom columns */

/* Create two equal columns that floats next to each other */
.column {
    float: none;
    width: 100%;
    padding: 0;
}

@media (min-width: 800px) {
.column {
    float: left;
    width: 50%;
    padding: 10px;
}}

/* Clear floats after the columns */
.row:after {
    clear: both;
}

/* Make the filter scrollable */
.select-scrollable{
    max-height: 100px;
    overflow-y: scroll;
}

.table-compact {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: 1px;
      }
    }
  }
}
