.display-flex {
  display: flex;
}

.div-flex-content-center {
  display: flex;
  align-items: center;
  justify-content: center;

  &.flex-fd--col {
    flex-direction: column;
  }
}

.div-flex-content-right {
  display: flex;
  align-items: center;
  justify-content: right;
}

.div-flex-content-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.div-flex-align-center {
  display: flex;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.align-items-end {
  align-items: flex-end;
}

/* gap sizing - Can only be applied to flexbox elements*/

.g-none {
  gap: $width-none;
}

.g-xxs {
  gap: $width-xxs;
}

.g-xs {
  gap: $width-xs;
}

.g-sm {
  gap: $width-sm;
}

.p {
  gap: $width-def;
}

.g-md {
  gap: $width-md;
}

.g-lg {
  gap: $width-lg;
}

.g-xl {
  gap: $width-xl;
}
