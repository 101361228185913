@import 'variables.scss';

$yd-orange: #ff9e19;

/* Complementary Colors */
$orange1: #FFC681;
$orange2: #FFB358;
$orange3: #F79D31; /* def */
$orange4: #D87B0B;
$orange5: #AA5C00;

/* Complementary Colors */
$blue1: #45A5CF;
$blue2: #1D96CB;
$blue3: #058CC7; /* def */
$blue4: #03648E;
$blue5: #024E6F;

/* MARGINS & PADDINGS */

$width-none: 0;
$width-xxs: 5px;
$width-xs: 6px;
$width-sm: 10px;
$width-def: 13px;
$width-md: 20px;
$width-lg: 30px;
$width-xl: 40px;


// Spinner color and margin
$spin-color: $blue2;
$spin-margin: 0 auto;

// entities
$forest-green: #5D865E;
$simple-orange: #F7A037;
$break-xs: 768px;

/* Ag-grid YD */
$header-background-color: #eeeeee;
$row-highlighter-color: skyblue;
$cell-font-size: 11px;
$cell-header-font-size: 11px;
$cell-border: #ddd;
$button-border: #ccc;
$cell-padding-height: 2px;
$cell-padding-width: 4px;
$table-border: $cell-border;

//** Point at which the eeh navbar begins collapsing
$grid-eeh-float-breakpoint: 1152px !default;
//** Point at which the eeh navbar begins collapsing
$grid-eeh-float-breakpoint-max: ($grid-eeh-float-breakpoint - 1) !default;
