$color-primary: #D9D9D9;

.transfer-container {
    margin: 200px;
    display: flex;
}

.transfer {
    border: 1px solid $color-primary;
    border-radius: 5px;
    color: #666;
    display: inline-block;
    vertical-align: top;

    * {
        box-sizing: border-box;
    }

    &-head {
        border-bottom: 1px solid $color-primary;
        margin: 5px 0;
        .type {
            float: right;
        }
    }

    &-body {
        height: 250px;
    }

    &-search {
        text-align: left;
        margin: 5px 0;

        input {
            border-radius: 5px;
            border: 1px solid $color-primary;
        }
    }

    &-list {
        max-height: 200px;
        overflow-y: scroll;
        margin: auto;

        li label {
            margin: 0;
            display: block;
            cursor: pointer;
            transition: .3s;

            &:hover {
                background: #EAF8FE;
            }
        }
    }
}

@media (max-width: 768px) {
    .transfer-operation {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
    }
}

.transfer-operation {
    vertical-align: top;
    align-self: center;
    float: left;
    margin: 0px 30px;

    button {
        margin-bottom: 5px;
        display: block;
    }
}
