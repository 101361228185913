/* Offer Caps */
.caps-panel {
    border-radius: 0;

    .panel-header {
        padding: 2px 5px;
    }

    .panel-body {
        padding-bottom: 0;
    }

    .well {

    }

    .cap-header {
        padding: 3px 20px;
    }

    .cap-row {
        padding: 14px;
        margin: 20px 6px;
    }

    .control-text {
        line-height: 20px;
        margin-top: 5px;
        font-weight:600;
    }

    /* Offer Redirects */
    .control-icons {
        font-size: 1.2em;
        line-height: 32px;
    }

    &.panel-success {
        .control-icons {
            color: $state-success-text;
        }
        .control-icons:hover {
            color:lighten($state-success-text, 15)
        }
    }

    &.panel-warning {
        .control-icons {
            color: $state-warning-text;
        }
        .control-icons:hover {
            color:lighten($state-warning-text, 15)
        }
    }

}

/* Offer Redirect Rules */
.redirect-rule-panel {
    border-radius: 0;

    .panel-header {
        padding: 2px 5px;
    }

    .panel-body {
        padding-bottom: 0;
    }

    .control-text {
        line-height: 20px;
        margin-top: 5px;
        font-weight:600;
    }

    /* Offer Redirects */
    .control-icons {
        font-size: 1.2em;
        line-height: 32px;
    }

    &.panel-info {
        .control-icons {
            color: $state-info-text;
        }
        .control-icons:hover {
            color:lighten($state-info-text, 15)
        }
    }

    &.panel-danger {
        .control-icons {
            color: $state-danger-text;
        }
        .control-icons:hover {
            color:lighten($state-danger-text, 15)
        }
    }
}

/* Offer Redirect Actions */
.redirect-action-panel {
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: none;

    .panel-header {
        padding: 2px 5px;
        background-color: white;
    }

    .panel-body {
        padding-top: 0;
    }

    .control-text {
        line-height: 20px;
        margin-top: 5px;
        font-weight:600;
    }

    /* Offer Redirects */
    .control-icons {
        font-size: 1.2em;
        line-height: 32px;
        color: gray;
    }

    /* Offer Redirects */
    .control-icons:hover {
        color:lighten(gray, 15)
    }
}

.thumbnail-placeholder {
    width: 100px;
    height: 100px;
    background-color: lightgrey;
    border: dotted 3px whitesmoke;
    padding:5px;
}

.hover-image {
    vertical-align: top;

    .thumbnail {
        width: 100px;
        height: 100px;
        border: solid 3px lightgrey;
        padding-top: 10px;
    }

    .remove {
        color: red;
    }

    .full-image {
        box-shadow: 5px 5px 10px #888888;
        border: solid 3px whitesmoke;
        border-radius: 2%;
        max-height: 1000px;
        max-width: 1000px;
    }

    a {
        position: relative;
    }

    a .hide-image {
        position: fixed;
        display: none;
        z-index: 99;
        left: 50%;
        top: 50%;
    }

    a:hover span {
        display: block;
        position: fixed;
        left: 50%;
        top: 50%;
        -ms-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

.file-limit {
    color:grey;
    font-size: 10px;
}

.creative-block {
    display:inline-block;
    list-style-type: none;
    padding-right: 20px;
    margin-bottom:10px;
}

.pointer {
    cursor: pointer
}
