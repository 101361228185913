.tag {
  display: inline-block;
  padding: 5px 10px;
  font-size: 80%;
  font-weight: 100;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;

  .control-icons:hover {
    cursor: pointer;
  }
}

.tag:empty {
  display: none;
}

.btn .tag {
  position: relative;
  top: -1px;
}

a.tag:focus, a.tag:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.tag-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.tag-default {
  background-color: #818a91;
}

.tag-default[href]:focus, .tag-default[href]:hover {
  background-color: #687077;
}

.tag-primary {
  background-color: #0275d8;
}

.tag-primary[href]:focus, .tag-primary[href]:hover {
  background-color: #025aa5;
}

.tag-success {
  background-color: #5cb85c;
}

.tag-success[href]:focus, .tag-success[href]:hover {
  background-color: #449d44;
}

.tag-info {
  background-color: #5bc0de;
}

.tag-info[href]:focus, .tag-info[href]:hover {
  background-color: #31b0d5;
}

.tag-warning {
  background-color: #f0ad4e;
}

.tag-warning[href]:focus, .tag-warning[href]:hover {
  background-color: #ec971f;
}

.tag-danger {
  background-color: #d9534f;
}

.tag-danger[href]:focus, .tag-danger[href]:hover {
  background-color: #c9302c;
}

.list-inline>li.tag {
  padding: 10px 10px;
}
