$foreground-opacity: 1 !default;
$secondary-foreground-color-opacity: 1 !default;
$disabled-foreground-color-opacity: 0.5 !default;

// Sizing
$grid-size: 4px !default;
$icon-size: 12px !default;
$header-height: $grid-size * 6 + 1 !default;
$row-height: ($grid-size * 6 + 1) !default;
$cell-horizontal-padding: $grid-size * 3 !default;
$virtual-item-height: $grid-size * 5 !default;
$header-icon-size: 14px !default;

// Icons
$icons-path: '../../ag-theme-base/icons/' !default;

// Fonts
$font-family: 'Helvetica Neue', sans-serif !default;
$font-size: 14px !default;
$font-weight: 400 !default;

$secondary-font-family: $font-family !default;
$secondary-font-size: 14px !default;
$secondary-font-weight: 400 !default;

// Tooltip
$tooltip-background-color: #535353 !default;
$tooltip-foreground-color: white !default;
$tooltip-border-radius: 2px !default;
$tooltip-padding: 5px !default;
$tooltip-border-width: 1px !default;
$tooltip-border-style: solid !default;
$tooltip-border-color: #ebebeb !default;

// Misc
$card-shadow: none !default;
$card-radius: 0 !default;
$row-border-width: 0 !default;
$transition-speed: 120ms !default;

$toolpanel-indent-size: $grid-size + $icon-size !default;
$row-group-indent-size: $grid-size * 3 + $icon-size !default;

$header-cell-moving-background-color: rgb(190, 190, 190) !default;
$input-disabled-background-color: #ebebeb !default;
$cell-horizontal-border: none !default;
$scroll-spacer-border: 1px solid if($border-color, $border-color, lightgrey) !default;