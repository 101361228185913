@import "../scssVendor/bootstrap/variables";
@import "../scssVendor/bootstrap/mixins";
@import "../yd-variables";
@import "mixins";
@import "globals";
@import "padding-margins";
@import "div_custom_layouts";
@import "media_query_blocks";
@import "spinners";
@import "ybox";
@import "login-reset";
@import "offers";
@import "email";
@import "chevron_breadcrumbs";
@import "bs4-tags";
@import "bs4-form-controls";
@import "textarea";
@import "navbar";
@import "bootstrap-ui-override";
@import "bs4-cards";
@import "link-yd";
@import "transfer-box";
@import "precision-stats";
@import "home";
@import "bs4-grid";
@import "performance-bar";
@import "custom-modal";
@import "item_well";
@import "approval-status-displays";
@import "tags";
@import "typography";
@import "img";

.select2-container .select2-choice {
  line-height: 28px;
  height: 30px;
}

/*vendor */
@import "ui-select-0.19.5";
@import "sweet-alert";
@import "toastr";
@import "toastr-homer";
@import "daterangepicker";
@import "isteven-multi-select";
@import "ag-grid-theme-yd";
@import "ace-editor";
@import "api-token";
@import "smart-admin";
