html, body.smart-style-2 {
    background: $body-bg;
}

@media only screen and (max-width: 768px) {
    .info-bar > .row > div {
        border-bottom: 1px solid #ddd;
    }

    .info-bar.container-fluid {
        padding: 0;
    }
}


/**
 * Breadcrumbs
 */
.breadcrumb {
    padding: 2px 0 2px 5px !important;
    margin: 0;
}

ol.breadcrumb {
    font-size: 0;
}

ol.breadcrumb > li {
    font-size: 10px;
    display: inline-block;
}
