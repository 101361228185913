.item-capsule {
    background-color: $required-item;
    border-radius: 25px;
    display: inline-block;
    color: white;
    padding: 5px 10px;
    margin: 3px;
}

.item-well {
    background-color: $item-well-bg;
    padding: 15px;
}

.item-well-sm {
    background-color: $item-well-bg;
    padding: 10px;
}
