$default-offertag-bg-color:         #659ffc;

.offertag {
    background-color: $default-offertag-bg-color;
    border-radius: 25px;
    display: inline-block;
    color: white;
    padding: 5px 10px;
    margin: 3px;
}
