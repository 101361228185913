.precision-stats {
    .form-group {
        margin-bottom: 0;
    }

    .control-label {
        font-size: 13px;
    }

    .ag-yd-style {
        height: 100%;
    }

    .border-top-dashed {
        border-top: #ddd dashed 1px;
    }
}
