@keyframes ticker-animation {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-100%, 0, 0);
    }
}

.ticker-animated {
    padding-left: 100%;
    display: inline-block;
    white-space: nowrap;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker-animation;
    animation-duration: 60s;
}

.ticker-animated:hover {
    animation-play-state: paused;
}

.performance-bar {
    display: flex;
    overflow: hidden;
    font-size: 10px;

    @media (min-width: 768px) {
      width: 108%;
      margin-left: -7%;
    }
}

.network-metric {
    color: #333;
}

.network-metric-name,
.ticker-title {
    padding: 2px 6px;
    border-left: 1px solid #ddd;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}

.network-metric-number {
    padding: 2px 4px;
    font-weight: bold;
}

.ticker {
    overflow: hidden;
}

.ticker-body {
    padding: 2px 0;
    overflow: hidden;
    flex: 0 0 20%;
}
