.widget .outline {
    border: 1px solid #e7eaec;
}

.widget h2 {
    margin-top: 0;
    font-size: 20px;
}

.card {
    margin-bottom: 10px;
    width: 80%;
    margin-left: 20%
}

.yellow-bg {
    background-color: saturate($brand-warning, 25%);
    color: #ffffff;
}

.red-bg {
    background-color: saturate($brand-danger, 25%);
    color: #ffffff;
}

.adjusted-gray-bg {
    background-color: saturate($brand-success, 25%);
    color: #ffffff;
}

.green-bg {
    background-color: saturate($brand-success, 25%);
    color: #ffffff;
}
